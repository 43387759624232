<template>
  <div class="home">

    <div class="intro">
      Eso que acabas de decir se merece <span id="intro-text"></span>
    </div>

    <div class="answer">
        <span id="answer-text">UNA POLLA COMO UNA OLLA</span>
    </div>


    <div class="footer">
        <p>
            Respuesta generada por nuestro sistema, basado en Machine Learning, para la detección de personas a las que hay que dar de comer aparte.
        </p>
    </div>

  </div>
</template>

<script>

export default {
  name: 'HomeView',
}
</script>
