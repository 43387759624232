<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<style>
#app {
  font-family: 'Fira Mono', monospace;
  background-color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

div.intro
{
  text-align: center;
  margin: 15vh auto;;
  font-size: 2rem;
  height: 10vh;
}

span#intro-text
{
  text-decoration: underline;
}

div.answer
{
  text-align: center;
  font-weight: bold;
  font-size: 3rem;
  padding: 0 12px 12px 12px;
  height: 30vh;
}

div.footer
{
  border-top: 2px solid #bbb;
  padding: 16px;
  p {
    margin: 0;
    padding: 8px 0;
  }
}

</style>
